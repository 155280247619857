import axios from "axios";
import React, { useEffect, useState } from "react";
import { config } from "../../config";
import { BeatLoader } from "react-spinners";

import "../manage_rack/racklist.css";

//   ///////
const RackList = (prop) => {
  const rack_edithandler = prop.fun;


  const [racks, setRacks] = useState([]);
  const [totalPages, setTotalpages] = useState();
  const [pageNumber, setPageNumber] = useState();

  

  const [errorMessage, setErrorMessage] = useState("no record");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);


  ///------ fetch data ---------///
  useEffect(() => {
    async function fetchrack() {
      setLoading(true);
      let payload = {
        fn: "common_fn",
        se: "fe_ra",
        data: { limit: 10, page: currentPage },
      };

      let reqbody = JSON.stringify(payload);
      // Fetch racks data from
      try {
        const response = await axios.post(
          `${config.apiBaseURL}/common`,
          { payload: reqbody },
          { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
        );

     
        const data = response.data.data;
        if (response.status === 200) {
          if (response.data.status === 0 && data.length > 0) {
            setRacks(data[0]);
            setTotalpages(data[1]);
          } else if (response.data.status === 1) {

            setRacks([]);
            setErrorMessage('no record')
          }
        }
      } catch (error) {
        console.log(error);
        setErrorMessage("Oops, something went wrong")
      }

      setLoading(false);
    }
    fetchrack();
  }, [currentPage]);

  // Change page
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //edit button handdler ///

  const editbtnhandler = (rack) => {
    rack_edithandler(rack);
  };

  function handleCurrentPage(currentPage) {
    if (currentPage <= totalPages && currentPage >= 0) {
      setCurrentPage(Number(currentPage));
    } else {
      alert("अमान्य पृष्ठ संख्या!");
    }
  }


  return (
    <div className="rack-list-container">
      {loading ? (
        <BeatLoader color="#123abc" className="spiner-postion" />
      ) : (
        <div>
          {racks && !racks.length > 0 ? (
            <p>{errorMessage}</p>
          ) : (
            <table className="rack-table">
              <thead>
                <tr>
                  <th>Rack No.</th>
                  <th>Rack Name</th>
            
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {racks.map((rack) => (
                  <tr key={rack.id} className="rack-row">
                    <td>{rack.racknumber}</td>
                    <td className="rack-name">{`${rack.room} - ${rack.almari} - ${rack.rackname}`}</td>
           
                    <td className="rack-name">
                      <button
                        onClick={() => editbtnhandler(rack)}
                        className="btn-edit-r"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}

      <div className="sub-page-div">
        <button
          className={`${
            currentPage === 1 ? "pagination-btn-disabled" : "pagination-btn "
          }`}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="Page-text">Page {currentPage}/{totalPages}</span>
        <button
          className={`${
            currentPage === totalPages || racks.length < 10 ? "pagination-btn-disabled" : "pagination-btn "
          }`}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages || currentPage < 1 || racks.length < 10}
        >
          Next
        </button>
      </div>

        <button
            className="page_number_button"
            onClick={(e) => {
              handleCurrentPage(pageNumber);
            }}
          >
            Go to page number{" "}
          </button>
          <input
            type="text"
            className="page_number"
            placeholder="1"
            onChange={(e) => {
              setPageNumber(e.target.value);
            }}
          />
    </div>
  );
};

export default RackList;
