import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { config } from "../../config";
import axios from "axios";
import "./import_books.css";

const Import_books = () => {
  const [departments, setDepartments] = useState([]);
  const [subdepartments, setSubDepartments] = useState([]);
  const [rack_no, setRack_no] = useState([]);

  const [file, setFile] = useState();

  const [isDialogBox, setIsDialogBox] = useState(false);

  const selectFileBtn = useRef(null);

  const [searchTerm_s, setSearchTerm_s] = useState("");
  const [selectrack_no, setSelect_Rack_no] = useState("");
  const [Select_sub_dept, SetSelect_sub_dpt] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [valid, setValid] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm_rack, setSearchTerm_rack] = useState("");

  // const [loading, setLoading] = useState(false);

  async function fetchdepart() {
    let payload = {
      fn: "common_fn",
      se: "fe_dpt",
      data: { limit: null, page: null },
    };

    let reqbody = JSON.stringify(payload);
    // Fetch racks data from
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data.data;
      if (response.status === 200 && response.data.status === 0) {
        setDepartments(data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  
  /// --------------------------- fetch sub department -------------- ------------- ///

  async function fetchsubdepart(value) {
    let payload = {
      fn: "common_fn",
      se: "fe_sub_dpt_id",
      data: { dept_id: value },
    };
    let reqbody = JSON.stringify(payload);
    // Fetch racks data from
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data.data;
      console.log(data);
      if (response.status === 200 && response.data.status === 0) {
        setSubDepartments(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);

    setSubDepartments([]);
    SetSelect_sub_dpt("");

    //------ fetch  sub department base on department ---------//

    fetchsubdepart(selectedOption.value);

    find_length(selectedOption.value).then((num) => {
      //-------------- append  sub deparment name  with book number ------------//
      // setBook_no(`${selectedOption.label.substring(0, 4)}-`);
    });
  };

  const find_length = async (id) => {
    let payload = {
      fn: "common_fn",
      se: "fe_bo_dept",
      data: { dept_id: id },
    };

    let reqbody = JSON.stringify(payload);
    // setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data.data;
      console.log(data.length);
      if (response.status === 200 && response.data.status === 0) {
        return data.length;
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const filteredDepartments = departments.filter((department) =>
    department.deptname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const departmentOptions = filteredDepartments.map((department) => ({
    value: department.row_id,
    // label: department.deptname,
    label: `${department.deptname} ${department.deptname_hindi}`,
  }));

  const handleSearchTermChange_r = (inputValue) => {
    setSearchTerm_rack(inputValue);
  };

  const handleSearchTermChange = (inputValue) => {
    setSearchTerm(inputValue);
  };

  const handleSubSearchTermChange = (inputValue) => {
    setSearchTerm_s(inputValue);
  };

  const filteredSubDepartments = subdepartments.filter((department) =>
    department.subdeptname.toLowerCase().includes(searchTerm_s.toLowerCase())
  );

  const SubdepartmentOptions = filteredSubDepartments.map((department) => ({
    value: department.row_id,
    label: `${department.subdeptname}  ${department.subdeptname_hindi}`,
  }));

  const find_length_sub_dept = async (id) => {
    let payload = {
      fn: "common_fn",
      se: "fe_sub_co",
      data: { subdept_id: id },
    };

    let reqbody = JSON.stringify(payload);
    // setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data.data;
      //   console.log(data.length);
      if (response.status === 200 && response.data.status === 0) {
        return data.length;
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const handleSubDepartmentChange = (selectedOption) => {
    // console.log(selectedOption);
    SetSelect_sub_dpt(selectedOption);

    find_length_sub_dept(selectedOption.value).then((n) => {});
  };

  async function fetchrack() {
    let payload = {
      fn: "common_fn",
      se: "fe_raks",
      data: { limit: null, page: null },
    };

    let reqbody = JSON.stringify(payload);
    // Fetch racks data from
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );

      const data = response.data.data;
      //   console.log(data);
      if (response.status === 200) {
        if (response.data.status === 0 && data.length > 0) {
          //   console.log(data[0]);
          setRack_no(data[0]);
        } else if (response.data.status === 1) {
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const filteredrack = rack_no.filter((rack) => {
    const term = searchTerm_rack.split("-");
    // console.log(term)
    if (
      rack.room.includes(term[0]) ||
      rack.almari.includes(term[1]) ||
      rack.rackname.includes(term[2])
    ) {
      return true;
    }else{
      return false;
    }
  });

  const handleRackChange = (selectedOption) => {
    // console.log(selectedOption);
    setSelect_Rack_no(selectedOption);
  };

  const rackOptions = filteredrack.map((rack) => ({
    value: rack.row_id,
    label: `${rack.room}-${rack.almari}-${rack.rackname}`,
  }));

  function selectFile(file) {
    console.log(file);
    if(file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      alert("Please select only excel file")
    }else{
      setFile(file);
    }
  }

  function dialoagBox() {
    setIsDialogBox(true);
  }

  async function submitData() {
    if (!selectedDepartment || !Select_sub_dept || !selectrack_no) {
      alert("Please fill all the fields");
      return;
    }

    if (!file) {
      selectFileBtn.value = ' '
      alert("Please select a file");
      return;
    }
    let formData = new FormData();
    formData.append("file", file);
    formData.append("department_id", selectedDepartment?.value);
    formData.append("sub_department_id", Select_sub_dept?.value);
    formData.append("rack_id", selectrack_no?.value);
    try {
      const resp = await axios.post(
        `${config.apiBaseURL}/common/booksupload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (resp.status === 201) {
        alert(resp.data);
        setFile("");
        setSelectedDepartment("");
        SetSelect_sub_dpt("");
        setSelect_Rack_no("");
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data)
    }
  }

  async function downloadTemplate() {
    const payload = {
      fn: "common_fn",
      se: "download_excel_book_template_import",
      authorization: localStorage.getItem("token"),
      access: localStorage.getItem("access"),
      data: "",
    };
    const reqbody = JSON.stringify(payload);
    try {
      const resp = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        {
          headers: { "Content-Type": "application/json" },
          responseType: "blob",
        }
      );
      console.log(resp);

      const contentType = resp.headers["content-type"];

      if (contentType === "application/json") {
        // this.snackbar.show(resp.data.msg);
        console.log(resp.data);
      } else if (
        contentType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        if (resp.data) {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = "Books_Template.xlsx";
          a.click();
        }
      }
    } catch (error) {
      console.log(error.response.data);
      // if (error.response.status == 404) {
      //   console.log(229)
      // }
      // if (error.response.status == 500) {
      //   console.log()
      // }
    }
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      margin: "0",
      width: "205px",
      height: "30px",
      minHeight: "35px",
      fontSize: "13px",

      // marginBottom :"20px"

      // Set the height of the input
    }),
    menu: (provided) => ({
      ...provided,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px",
      overflowY: "scroll", // Add the 'y' axis scrolling
      fontSize: "13px",
    }),
  };

  const customStyles2 = {
    control: (provided) => ({
      ...provided,
      margin: "0",
      width: "205px",
      height: "30px",
      minHeight: "35px",

      border: "2px solid red",

      // marginBottom :"20px"

      // Set the height of the input
    }),
    menu: (provided) => ({
      ...provided,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px",
      overflowY: "scroll", // Add the 'y' axis scrolling
    }),
  };

  useEffect(() => {
    fetchdepart();
    fetchrack();
  }, []);

  return (
    <>
      {isDialogBox && (
        <div className="dialogBoxContainer">
          <div className="dialogBox">
            <span>
              If you have template than click 'Yes' button else click on
              'Download' button.
            </span>
            <div className="buttons">
              <button
                onClick={() => {
                  selectFileBtn.current.click();
                  setIsDialogBox(false);
                }}
              >
                Yes
              </button>
              <button
                onClick={(e) => {
                  setIsDialogBox(false);
                  downloadTemplate();
                }}
              >
                Download
              </button>
              <button onClick={() => setIsDialogBox(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <div className="contentContainer">
        <label htmlFor="department_select" className="input-label-b">
          Import Books
        </label>
        <div className="inputFields">
          <div className="select_input">
            <label htmlFor="department_select" className="input-label-b">
              Select Dpartment
            </label>
            <Select
              id="department_select"
              value={selectedDepartment}
              onChange={handleDepartmentChange}
              options={departmentOptions}
              isSearchable
              placeholder="Search or select"
              onInputChange={handleSearchTermChange}
              styles={valid ? customStyles2 : customStyles}
            />
          </div>

          <div className="select_input">
            <label htmlFor="department_select" className="input-label-b">
              Select Sub-Dpartment
            </label>
            <Select
              id="subdepartment_select"
              value={Select_sub_dept !== null ? Select_sub_dept : ""}
              onChange={handleSubDepartmentChange}
              options={SubdepartmentOptions}
              isSearchable
              placeholder="Search or select"
              onInputChange={handleSubSearchTermChange}
              // isDisabled={}
              styles={valid ? customStyles2 : customStyles}
            />
          </div>

          <div className="select_input">
            <label htmlFor="rack_select" className="input-label-b">
              Rack
            </label>
            <div>
              <Select
                id="rack_select"
                value={selectrack_no}
                onChange={handleRackChange}
                options={rackOptions}
                isSearchable
                placeholder="Search or select"
                onInputChange={handleSearchTermChange_r}
                styles={valid ? customStyles2 : customStyles}
              />
            </div>
          </div>

          <div className="select_input">
            <label className="input-label-b">Select file</label>
            <input
              type="file"
              ref={selectFileBtn}
              className="fileInput"
              onChange={(e) => selectFile(e.target.files[0])}
            />
            <button className="selectBtn" onClick={(e) => dialoagBox()}>
              Select File
            </button>
            <label>{file && file.name}</label>
          </div>
        </div>

        <button className="uploadBtn" onClick={(e) => submitData()}>
          Upload
        </button>
      </div>
    </>
  );
};

export default Import_books;
