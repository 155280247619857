import React, { useState } from "react";

import "./managerack.css";
import RackList from "./racklist";
import RackIdgenerate from "../../services/Helperfunction";
import { BeatLoader } from "react-spinners";
import SuccessTick from "../../componets/Modal/Modal";
import { config } from "../../config";
import axios from "axios";

const Managerack = (prop) => {
  // const uniqueId = RackIdgenerate.Rackunique_id();


  //  ----UI State ------ //

  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showlist, setshowlist] = useState(false);
  const [valid, setvalid] = useState(true);
   const [showtick,SetShowtick]   = useState(false)



  //  --- other data state   ---- --//
  const [name, setName] = useState("");

  const [room, setRoom] = useState("");
  const [almira,setAlmira] =  useState("");
  const [rackno, setRackno] = useState("");
  const [row_id, setrow_id] = useState(null);


  // show-list  //////////////

  const [validationStatus, setValidationStatus] = useState({
    rack_id: false,
    rack_name: false,

   
  })

  ///   success msg display show   //////////////
  // const [successmsg, setsuccessmsg] = useState(false);

  ///// -------input change handler------- /////

  const Input_change_handler = (e) =>{

if(e.target.name ==="rack_id"){
  if(e.target.value){
    setvalid(true);
  }

setRackno(e.target.value.replace("'", "`"))

}

if(e.target.name ==="rackname"){

  if(e.target.value){
    setvalid(true);
  }

setName(e.target.value.replace("'", "`"));

}


if(e.target.name ==="room_name"){

setRoom(e.target.value.replace("'", "`"))

}

if(e.target.name ==="Almirah"){

setAlmira(e.target.value.replace("'", "`"))


}



  }




  //---------------------- create new rack ---------------------- ///////

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (name.trim().length === 0 ||  almira.trim().length === 0 || room.trim().length === 0) {
      setvalid(false);
      return;
    }

    const data = { rackname: name, racknumber: rackno ,almirah:almira,room:room};
    console.log(data);
    let payload = { fn: "common_fn", se: "cr_ra", data: data };

    let reqbody = JSON.stringify(payload);

    setLoading(true)

    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );

      console.log(response);
      if (response.status === 200) {
        if (response.data.status === 0) {
          setErrorMessage(response.data.msg);
        } else {
          setErrorMessage(response.data.msg || "Ops something went wrong");
        }
        setLoading(false);
      } else {
        const { msg } = await response.data;
        setLoading(false);

        setErrorMessage(msg);
      }
    } catch (error) {
      console.log(error);
      
      setErrorMessage("opp something went go");
    }
    setLoading(false);



  };

  ///////------------------ rack update----------------  ///////

  const handleupdate = async (event) => {
    event.preventDefault();

    const data = {
      rackname: name,
      racknumber: rackno,
      almirah:almira,room:room,
      row_id: row_id,
    };
    console.log(data);
    let payload = { fn: "common_fn", se: "ed_ra", data: data };

    let reqbody = JSON.stringify(payload);

    setLoading(true)

    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );
    
      // console.log(response);
      if (response.status === 200) {
        if (response.data.status === 0) {
          setErrorMessage(response.data.msg);
        } else {
          setErrorMessage(response.data.msg || "Ops something went wrong");
        }
        setLoading(false);
      } else {
        const { msg } = await response.data;
        setLoading(false);

        setErrorMessage(msg || "Ops something went wrong");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);

      setErrorMessage("opp something went go");
    }

    setLoading(false)
  };

  // rack list show ///////////
  const showlisthandler = () => {
    setshowlist(true);
  };

  // rack edit handler    //
  // const [updatebtn, setupdatebtn] = useState(false);

  const Rack_edithandler = (rackobj) => {
    setshowlist(false);

    setName(rackobj.rackname);

    setRackno(rackobj.racknumber);
    setrow_id(rackobj.row_id);
    setRoom(rackobj.room)
    setAlmira(rackobj.almari)
    // setCapacity(rackobj.capacity);
    // setupdatebtn(true);
  };

  return (
    <div>
{ loading ? <BeatLoader/>  :

   <div>


      {!errorMessage ? (
        <div>
          {!showlist ? (
            <div className="form-container">
              <h6 style={{textAlign:"left",width:"100%", fontWeight:"bold", fontSize:"1.6rem"}}>
                {row_id ? "Update" : "Create New Rack"}
              </h6>



              <div className="input-row">
                <div>
                  <label htmlFor="room_name" className="input-label">
                    Room name
                  </label>
                  <div className={`input-container ${!valid ? "valids" : ""}`}>
                    <input
                      type="text"
                      name ="room_name"
                      id="room_name"
                      value={room}
               
                      onChange={Input_change_handler}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="almirah" className="input-label">
                  Almirah name
                  </label>
                  <div className={`input-container ${!valid ? "valids" : ""}`}>
                    <input
                      type="text"
                      name="Almirah"
                      id="almirah"
                      value={almira}
                      onChange={Input_change_handler}
                    />
                  </div>
                </div>
              </div>

              <div className="input-row">
                <div>
                  <label htmlFor="full-name" className="input-label">
                    Rack.no
                  </label>
                  <div className={`input-container ${!valid ? "valids" : ""}`}>
                    <input
                      type="text"
                      name ="rack_id"
                      id="full-name"
                      value={rackno}
               
                      onChange={Input_change_handler}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="rack.no" className="input-label">
                    Rack name
                  </label>
                  <div className={`input-container ${!valid ? "valids" : ""}`}>
                    <input
                      type="text"
                      name="rackname"
                      id="rack.no"
                      value={name}
                      onChange={Input_change_handler}
                    />
                  </div>
                </div>
              </div>




         



              
              <div className="input-rowss">
                <button
                  className="submit-btn-rack"
                  type="submit"
                  onClick={row_id ? handleupdate : handleSubmit}
                >
                  {row_id ? "Update" : "Submit"}
                </button>

                <span className="view-rack" onClick={showlisthandler}>
                  View Rack
                </span>
              </div>
            </div>
          ) : (
            <RackList fun={Rack_edithandler} />
          )}
        </div>
      ) :
        <p className="success-msg">{errorMessage}</p>
       
      }

</div>



      }

    </div>
  );
};

export default Managerack;
