import React, { useState } from "react";
import "./../book_template/book_template.css";
import { config } from "../../config";
import { BeatLoader } from "react-spinners";
import axios from "axios";
// import jsPDF from "jspdf";
import "jspdf-autotable";

// import html2canvas from "html2canvas";
// import html2pdf from 'html2pdf.js';
// import logo from "./book_logo.png";
// import ImageSlider from "../All_book/image_slider";

import ImgModal from "../All_book/ImgModal";
import def from "./bookNa.png";

function Book_template({
  data,
  totalPages,
  currentPage,
  loading,
  setCurrentPage,
  filters,
}) {
  const [show_slider, setshow_slider] = useState(false);
  const [Obj, setObj] = useState(true);
const [loader, setLoader] = useState(false);

const [pageNumber, setPageNumber] = useState(1)

  function open_slider_handler(obj) {
    if (obj.img_arr === null || obj.img_arr.length <= 0) {
      return;
    }

    setObj(obj);
    setshow_slider(true);
  }
  async function generatePDF() {
    setLoader(true)
    let payload = {
      fn: "common_fn",
      se: "gt_pdf",
      data: {
        deptFil: filters.deptFil,
        subDeptFil: filters.subDeptFil,
      },
    };

    let reqbody = JSON.stringify(payload);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {

      //   const res = await axios.get(`${config.apiBaseURL}/getpdffile`, {
      //     responseType: "blob"
      //   });
      //   console.log("60", res);

        // const blob = new Blob([res.data], { type: "application/pdf" });

        // const url = window.URL.createObjectURL(res.data);
        // setTimeout(()=>{

          const url = `${config.apiBaseURL}/PDF FILES/books.pdf`;
          // const url = `${config.apiBaseURL}/PDF FILES/books.pdf`;
          const link = document.createElement("a");
          
          link.download = "books.pdf";
          // link.href = url;
          
          document.body.appendChild(link);
          link.click();
          
          window.open(url, '_blank');
          // saveAs(url, "newPdf.pdf");
          document.body.removeChild(link);
          
          URL.revokeObjectURL(url);
          
        // }, [500])
        // const pdfblob = new Blob([res.data], {type:'application/pdf'});
        // console.log(pdfblob)
        // // const pdfblob = await res.blob();
        // const url = URL.createObjectURL(pdfblob);
        // URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false)
  }
  


function handleCurrentPage(currentPage){
    if(currentPage<=totalPages){
      setCurrentPage(Number(currentPage))
    }else{
      alert("अमान्य पृष्ठ संख्या!")
    }
}

  return (
    <>
      {loading ? (
        <BeatLoader />
      ) : (
        <div className="showbookStorebydk">
          {show_slider && (
            <ImgModal images={Obj} alt="image slider" setshow_slider={setshow_slider} />
          )}

          <div className="mainshowbookStorebydk" id="divToPrint">
            <div className="booksmainshowbookStorebydk">
              {data.length > 0 ? (
                data?.map((e, i) => {
                  // {console.log(e.img_arr[0])}
                  let publication=""

                  if(e.isvi_samvat){
                    publication = `ईस्वी सन्- ${e.isvi_samvat}`
                  }else if(e.vikram_samvat){
                    publication = `विक्रम संवत्- ${e.vikram_samvat}`
                  }else if(e.veer_samvat){
                    publication = `वीर संवत्- ${e.veer_samvat}`
                  }
                  return (
                    <div className="bookcart" key={i}>
                      <div className="bookcart_row_1">
                        <div className="bookcartimg">
                          <img
                            src={
                              e.img_arr !== null && e.img_arr.length
                                ? `${config.apiImageUrl}/${e.img_arr[0].url}`
                                : def
                            } 
                            alt={e.bookname}
                            onClick={() => {
                              open_slider_handler(e);
                            }}
                          />
                        </div>
                        <div className="bookcartcontent">
                          <div className="bold" id="booknametext">
                            {e.book_hindi_name !== null
                              ? e.book_hindi_name
                              : e.bookname}
                          </div>
                          <div className="bookdetailattribute bold">
                            {" "}
                            {e.authorname}
                          </div>
                          <div
                            className="bookdetailattribute bold"
                            id="publisher12"
                          >
                            {e.publisher !== null ? e.publisher : ""}
                          </div>
                          {/* <div className="bookdetailattribute bold">
                            {e.deptname != null ? e.deptname : ""}
                            </div>
                            <div className="bookdetailattribute bold">
                            {e.subdeptname != null ? e.subdeptname : ""}
                          </div> */}
                          <div className="bookdetailattribute bold">
                            {e.language}
                          </div>
                          <div className="bookdetailattribute bold">
                            {publication}
                          </div>
                          {/* <div className='bookdetailattribute bold'>पृष्ठ -{e.book_pages != null? e.book_pages :""}</div> */}
                        </div>
                        <div className="pageSection">
                          <div id="bk">
                            <div id="nk">
                            <img src="/book_no.png" alt="book number" className="scroll_image" />
                            <span className="book_no">{e.book_no}</span>
                           
                            </div>
                          </div>
                          <div className="bookdetailattribute bold pages">
                          <span> पृष्ठ = {e.pages != null ? e.pages : ""}</span>
                          </div>
                        </div>
                      </div>
                      <div className="bookcart_row_2">
                        <p className="bookdetailattribute2 bold">
                          {e.summary !== null ? e.summary : ""}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p style={{ textAlign: "center", width: "100%" }}> no data </p>
              )}
            </div>
          </div>
          <div className="btnmainshowbookStorebydk">
            <div>
              <button
                className={`${
                  currentPage === 1
                    ? "pagination-btn-disabled"
                    : "pagination-btn "
                }`}
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span style={{margin:"0px 5px"}}>page {currentPage}/{totalPages}</span>
              <button
                className={`${
                  currentPage === totalPages || data.length < 12 
                    ? "pagination-btn-disabled"
                    : "pagination-btn "
                }`}
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages || currentPage < 1 || data.length < 12}
              >
                Next
              </button>
              <br/>
              <button className="page_number_button" onClick={(e)=>{handleCurrentPage(pageNumber)}}>Go to page number </button>
              <input type="text" className="page_number" placeholder="1" onChange={(e)=>{setPageNumber(e.target.value)}} />
            </div>
            <div>
              <button
                style={{ backgroundColor: "green", color: "white" }}
                className="pagination-btn"
                onClick={generatePDF}
                download="newPdf.pdf"
              >
                {loader?<BeatLoader/> : <span>Save as PDF</span>}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Book_template;
