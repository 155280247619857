import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./Sub_department.css";
import { config } from "../../config";
import { BeatLoader } from "react-spinners";
import Sub_dep_list from "./Sub_department_list";

import axios from "axios";

const Subdepartment = () => {
  //------------UI state --------------//

  const [loading, setLoading] = useState(false);
  const [show_dept_list, setShow_dept_list] = useState(false);
  const [msg, setMsg] = useState(false);
  const [edit, SetEdit] = useState(false);
  const [isvalidate, Setisvalidate] = useState(false);
  const [dept_length, Setdept_lenght] = useState(false);
  // -----------------------data state --------------------- //

  const [subdept_id, SetsubDept_id] = useState("");
  const [subdept_name, Setsubdept_name] = useState("");
  const [subdept_hindi_name, Setsubdept_hindi_name] = useState("");

  const [row_id, SetRow_id] = useState(false);

  // ---------- input change handling ------------ //

  const Input_change_handler = (e) => {
    if (e.target.value.length > 0) {
      Setisvalidate(false);
    }

    if (e.target.name === "subdeptid") {
      SetsubDept_id(e.target.value.replace("'", "`"));
    }

    if (e.target.name === "subdeptname") {
      e.target.value.length >= 4 ? Setdept_lenght(false) : Setdept_lenght(true);
      Setsubdept_name(e.target.value.replace("'", "`"));
    }

    if (e.target.name === "subdeptname_hindi") {
      Setsubdept_hindi_name(e.target.value.replace("'", "`"));
    }
  };

  ///-----------------        ///////////

  useEffect(() => {
    fetchdepart();
  }, []);

  ///------------------------- -Create new Department-----------------------///

  const handleSubmit = async (event) => {
    event.preventDefault();

    // console.log("id",subdept_id,"name",subdept_name,'dept',selectedDepartment)

    if (
      (subdept_id.trim().length === 0 || subdept_name.trim().length === 0,
      subdept_hindi_name.trim().length === 0)
    ) {
      Setisvalidate(true);
      return;
    }

    if (subdept_name.trim().length < 4) {
      Setdept_lenght(true);
      return;
    }

    const data = {
      subdept_id: subdept_id.trim(),
      subdept_name: subdept_name.trim(),
      subdept_hindi_name: subdept_hindi_name.trim(),
      dept_row_id: selectedDepartment.value,
    };

    let payload = { fn: "common_fn", se: "cr_sub_dpt", data: data };

    let reqbody = JSON.stringify(payload);

    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );
      // const data = await response.json();
      // console.log(response);

      if (response.status === 200 && response.data.status === 0) {
        setMsg("Subdept Created Successfully");
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  // ----------------- Update sub Department -------------- //

  const Update_handler = async () => {
    const data = {
      subdept_id: subdept_id.trim(),
      subdept_name: subdept_name.trim(),
      subdept_hindi_name: subdept_hindi_name.trim(),
      row_id: row_id,
      dept_row_id: selectedDepartment.value,
    };

    // console.log(data)

    try {
      let payload = { fn: "common_fn", se: "ed_sub_dpt", data: data };

      let reqbody = JSON.stringify(payload);

      setLoading(true);

      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      // console.log(response);

      if (response.status === 200 && response.data.status === 0) {
        setMsg("Update Successful");
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  // ----------view list of dept --------  //

  const handle_view = () => {
    setShow_dept_list(true);
  };

  // -----state update function for edit from dpart list component ---- //

  const data_state_update = (data) => {
    SetsubDept_id(data.subdept_id);

    Setsubdept_name(data.subdeptname);
    SetRow_id(data.row_id);
    Setsubdept_hindi_name(data.subdeptname_hindi);
    setSelectedDepartment({ label: data.deptname, value: data.dept_row_id });
    setShow_dept_list(false);
    SetEdit(true);
  };

  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  ///------------------ --fetch  department---- - ------------------///

  async function fetchdepart() {
    let payload = {
      fn: "common_fn",
      se: "fe_dpt",
      data: { limit: null, page: null },
    };

    let reqbody = JSON.stringify(payload);
    // Fetch racks data from
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data.data;

      if (response.status === 200 && response.data.status === 0) {
        setDepartments(data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //------------- depatment input logic----------------//

  const [searchTerm, setSearchTerm] = useState("");

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
  };

  const handleSearchTermChange = (inputValue) => {
    setSearchTerm(inputValue);
  };

  const filteredDepartments = departments.filter((department) =>
    department.deptname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const departmentOptions = filteredDepartments.map((department) => ({
    value: department.row_id,
    label: `${department.deptname}  ${
      department.deptname_hindi !== null ? department.deptname_hindi : ""
    }`,
  }));

  const customStyless = {
    control: (provided) => ({
      ...provided,

      margin: "2px",
      width: "205px",
      height: "26px",
      minHeight: "35px", // Add a minimum height to the control
      marginBottom: "20px",
      fontSize: "13px",

      // Set the height of the input
    }),
    menu: (provided) => ({
      ...provided,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px",
      fontSize: "13px",
      overflowY: "scroll", // Add the 'y' axis scrolling
    }),
  };

  return (
    <>
      {loading ? (
        <BeatLoader />
      ) : (
        <>
          {show_dept_list ? (
            <Sub_dep_list fun={data_state_update} />
          ) : (
            <div>
              {msg ? (
                <p style={{ color: "green", fontWeight: "bold" }}> {msg} </p>
              ) : (
                <div className="contentContainer">
                  <p
                    style={{
                      width: "100%",
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "1.6rem",
                    }}
                  >
                    {edit ? "Update Sub Department" : "Create Sub Department"}
                  </p>
                  <div
                    className={`inputFields ${
                      isvalidate ? "InputRow-left1" : ""
                    }`}
                  >
                    <div className="inputField">
                      <label htmlFor="book-name" className="input-label-b">
                        Department
                      </label>
                      <div className="input-container-b">
                        <Select
                          id="department_select"
                          value={selectedDepartment}
                          onChange={handleDepartmentChange}
                          options={departmentOptions}
                          isSearchable
                          placeholder="Search or select"
                          onInputChange={handleSearchTermChange}
                          styles={customStyless}
                        />
                      </div>
                    </div>
                    <div
                      className={`inputField ${
                        isvalidate ? "InputRow-right1" : ""
                      }`}
                    >
                      <div>
                        <label htmlFor="book-name" className="input-label-b">
                          Sub Dept Id
                        </label>
                        <div className="input-container-b">
                          <input
                            type="text"
                            id="book-name"
                            name="subdeptid"
                            value={subdept_id}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className={`inputField ${
                        isvalidate ? "InputRow-left1" : ""
                      }`}
                    >
                      <label htmlFor="author" className="input-label-b">
                        Sub Dept Name(हिन्दी)
                      </label>
                      {/* <div className="input-container-b"> */}
                      <input
                        type="text"
                        id="author"
                        name="subdeptname_hindi"
                        value={subdept_hindi_name}
                        onChange={Input_change_handler}
                      />
                      {/* </div> */}
                    </div>

                    <div
                      className={`InputRow-right ${
                        isvalidate ? "InputRow-right1" : ""
                      }`}
                    >
                      <div>
                        <label htmlFor="author" className="input-label-b">
                          Sub Dept Name (Eng)
                        </label>
                        <div className="input-container-b">
                          <input
                            type="text"
                            id="author"
                            name="subdeptname"
                            value={subdept_name}
                            onChange={Input_change_handler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="InputRow-left">
                    <div className="input-container-jj">
                      <button
                        className="dept-btn"
                        onClick={edit ? Update_handler : handleSubmit}
                      >
                        {edit ? "Update" : "Submit"}
                      </button>
                    </div>
                    {!edit && (
                      <div className="input-container-jj">
                        <button className="dept-btn" onClick={handle_view}>
                          View
                        </button>
                      </div>
                    )}
                  </div>

                  {dept_length && (
                    <span
                      style={{
                        fontSize: "13px",
                        paddingTop: "15px",
                        color: "red",
                      }}
                    >
                      Sub department name should have minimum 4 characters
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Subdepartment;
