export const config = {
  // apiBaseURL:"http://192.168.29.27:4000",

  // apiBaseURL: "http://localhost:4000",
  // apiBaseURL: window.location.origin,
  apiBaseURL: "https://books.ratnasangh.com",

  apiImageUrl:"https://books.ratnasangh.com",
  // apiImageUrl:"http://localhost:4000"
};

// https://gitlab.com/fintech-development-team/client-mobile-app/library_project.git