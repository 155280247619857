import React, { useState } from "react";
import "./Department2.css";
import { config } from "../../config";
import { BeatLoader } from "react-spinners";
import Department_list from "./../manage_dept/Department_list";

import axios from "axios";

const Department = () => {
  //------------UI state --------------//

  const [loading, setLoading] = useState(false);
  const [show_dept_list, setShow_dept_list] = useState(false);
  const [msg, setMsg] = useState(false);
  const [edit, SetEdit] = useState(false);
  const [isvalidate, Setisvalidate] = useState(false);
  const [dept_length, Setdept_lenght] = useState(false);
  // -----------------------data state --------------------- //

  const [dept_id, SetDept_id] = useState("");
  const [dept_name, Setdept_name] = useState("");
  const [dept_name_hindi, Setdept_name_hindi] = useState("");

  const [row_id, SetRow_id] = useState(false);

  // ---------- input change handling ------------ //

  const Input_change_handler = (e) => {
    if (e.target.value.length > 0) {
      Setisvalidate(false);
    }

    if (e.target.name === "deptid") {
      SetDept_id(e.target.value.replace("'", "`"));
    }

    if (e.target.name === "deptname") {
      e.target.value.length >= 4 ? Setdept_lenght(false) : Setdept_lenght(true);
      Setdept_name(e.target.value.replace("'", "`"));
    }

    if (e.target.name === "deptname_hindi") {
      Setdept_name_hindi(e.target.value.replace("'", "`"));
    }
  };

  ///------------------------- -Create new Department-----------------------///

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      dept_id.trim().length === 0 ||
      dept_name.trim().length === 0 ||
      dept_name_hindi.trim().length === 0
    ) {
      Setisvalidate(true);
      return;
    }

    if (dept_name.trim().length < 4) {
      Setdept_lenght(true);
      return;
    }

    const data = {
      dept_id: dept_id.trim(),
      dept_name: dept_name.trim(),
      dept_name_hindi: dept_name_hindi.trim(),
    };
    console.log(data);
    let payload = { fn: "common_fn", se: "cr_dpt", data: data };

    let reqbody = JSON.stringify(payload);

    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );
      // console.log(response);

      if (response.status === 200 && response.data.status === 0) {
        setMsg("Dept created Successfully");
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  // ----------------- Update Department -------------- //

  const Update_handler = async () => {
    try {
      if (
        dept_id.trim().length === 0 ||
        dept_name.trim().length === 0 ||
        dept_name_hindi.trim().length === 0
      ) {
        Setisvalidate(true);
        return;
      }

      if (dept_name.trim().length < 4) {
        Setdept_lenght(true);
        return;
      }

      const data = {
        dept_id: dept_id.trim(),
        dept_name: dept_name.trim(),
        deptname_hindi: dept_name_hindi.trim(),
        row_id: row_id,
      };

      let payload = { fn: "common_fn", se: "ed_dpt", data: data };

      let reqbody = JSON.stringify(payload);

      setLoading(true);

      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      // console.log(response);

      if (response.status === 200 && response.data.status === 0) {
        setMsg("Update Successful");
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  // ----------view list of dept --------  //

  const handle_view = () => {
    setShow_dept_list(true);
  };

  // -----state update function for edit from dpart list component ---- //

  const data_state_update = (data) => {
    console.log(data);
    SetDept_id(data.dept_id);

    Setdept_name(data.deptname);
    Setdept_name_hindi(data.deptname_hindi);
    SetRow_id(data.row_id);
    setShow_dept_list(false);
    SetEdit(true);
  };

  return (
    <>
      {loading ? (
        <BeatLoader />
      ) : (
        <>
          {show_dept_list ? (
            <Department_list fun={data_state_update} />
          ) : (
            <div>
              {msg ? (
                <p style={{ color: "green", fontWeight: "bold" }}> {msg} </p>
              ) : (
                <div className="contentContainer">
                  <p
                    style={{
                      width: "100%",
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "1.6rem",
                    }}
                  >
                    {edit ? "Update Department" : "Create Department"}{" "}
                  </p>

                  <div className="inputFields">
                    <div
                      className={`inputField ${
                        isvalidate ? "invalid-input" : ""
                      }`}
                    >
                      <label htmlFor="book-name" className="input-label">
                        Dept Id
                      </label>

                      <input
                        type="text"
                        id="book-name"
                        name="deptid"
                        value={dept_id}
                        onChange={Input_change_handler}
                      />
                    </div>

                    <div
                      className={`inputField ${
                        isvalidate ? "invalid-input" : ""
                      }`}
                    >
                      <label htmlFor="author" className="input-label">
                        Dept Name (हिन्दी)
                      </label>
                      <input
                        type="text"
                        id="author"
                        name="deptname_hindi"
                        value={dept_name_hindi}
                        onChange={Input_change_handler}
                      />
                    </div>

                    <div
                      className={`inputField ${
                        isvalidate ? "invalid-input" : ""
                      }`}
                    >
                      <label htmlFor="author" className="input-label">
                        Dept Name (Eng)
                      </label>
                      <input
                        type="text"
                        id="author"
                        name="deptname"
                        value={dept_name}
                        onChange={Input_change_handler}
                      />
                    </div>

                    {dept_length && (
                      <span
                        style={{
                          fontSize: "13px",
                          paddingTop: "30px",
                          color: "red",
                        }}
                      >
                        department name should have minimum 4 characters
                      </span>
                    )}
                  </div>

                  <div className="btns">
                    <div className="input-container-jj ">
                      <button
                        className="dept-btn"
                        onClick={edit ? Update_handler : handleSubmit}
                      >
                        {edit ? "Update" : "Submit"}
                      </button>
                    </div>
                    {!edit && (
                      <div className="input-container-jj">
                        <button className="dept-btn" onClick={handle_view}>
                          View
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Department;
